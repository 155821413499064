<template>
    <nav-bar></nav-bar>
    <template v-if="season">
        <div class="green-gradient p-5 flex justify-center">
            <div style="max-width:1200px">
                <div class="flex">
                    <img src="/flags/mx.png" class="mr-3" />
                    <h1 class="text-4xl">
                        Fantasy Football - Liga MX
                    </h1>
                </div>
                <p class="text-xl mt-2">
                    Show off your knowledge and compete against other Liga MX fans by playing Fantasy Liga MX. To get started all you need to do is use your 100m budget to pick a squad of 15 then each week select who'll be your 11 starters and 4 subs.
                </p>
            </div>
        </div>
        <div class="red-gradient p-5 flex justify-center">
            <div class="grid grid-cols-1 xl:grid-cols-[35%_65%] gap-4" >

                <div class="xl:block">
                    <div>
                        <img v-if="season.image_path" :src="season.image_path"  style="margin:0 auto;" />
                    </div>
                    <div class="grid grid-cols-6">
                        <div v-for="team in teams" class="pt-6 px-3">
                            <img v-if="team.image_path" :src="team.image_path" />
                        </div>
                    </div>
                </div>
                <div>
                    <div class="grid grid-cols-1 md:grid-cols-2">
                        <div class="mb-3 mx-3">
                            <h2 class="text-3xl mb-2">Advanced Stats</h2>
                            <p class="text-xl mb-2">
                                It's not just goals and clean sheets that earn you points, our scoring system uses key passes, shots on target, duels won, clearances, blocks, interceptions and more.
                            </p>
                        </div>
                        <div class="mb-3 mx-3">
                            <h2 class="text-3xl mb-2">Transfer Market</h2>
                            <p class="text-xl mb-2">
                                The best players cost the most, but breakout stars can be found for a bargain. Daily price changes give you the chance to get an edge over your opponents.
                            </p>
                        </div>
                        <div class="mb-3 mx-3">
                            <h2 class="text-3xl mb-2">Mini Leagues</h2>
                            <p class="text-xl mb-2">
                                Compete against the world in our global league or setup mini leagues with your friends, family or colleagues.
                            </p>
                        </div>
                        <div class="mb-3 mx-3">
                            <h2 class="text-3xl mb-2">Mini Games</h2>
                            <p class="text-xl mb-2">
                                We also have a Prediction League and a fixture specific blackjack/pontoon style game called 21.
                            </p>
                        </div>
                    </div>
                    <div v-if="season.status === 'active'" class="mt-3">
                        <router-link :to="{name:'season', params:{seasonId:season.id}}" class="btn" style="font-size:3rem;padding:.7rem 1rem">
                            Play Now
                        </router-link>
                        <p class="p-2">It's completely FREE to play.</p>
                    </div>
                    <div v-else class="mt-3">
                        <router-link :to="{name:'season', params:{seasonId:season.id}}" class="btn" style="font-size:3rem;padding:.7rem 1rem">
                            Sign Up
                        </router-link>
                        <p class="p-2">This league hasn't started yet. Sign up now to get an email when you can start picking your squad.</p>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="season">
            <div class="p-3">
                Below you can see a preview of the players, <router-link :to="{name:'season', params:{seasonId:season.id}}">start playing</router-link> to pick your team for real.
            </div>
            <player-list></player-list>
        </template>
    </template>
</template>

<script>
import {useDefaultStore} from "@/stores/defaultStore";
import {useSeasonStore} from "@/stores/seasonStore";
import {computed, onMounted, ref} from "vue";
import Fixtures from "../components/Fixtures.vue";
import Standings from "../components/Standings.vue";
import BetaText from "../components/BetaText.vue";
import TeamOfTheWeek from "../components/TeamOfTheWeek.vue";
import TransferStats from "../components/TransferStats.vue";
import SelectionStats from "../components/SelectionStats.vue";
import Explanation from "../components/Explanation.vue";
import PriceChangeStats from "../components/PriceChangeStats.vue";
import Gameweeks from "@/components/Gameweeks.vue";
import {useSeoMeta} from "@unhead/vue";
import OverallLeaderboard from "@/components/OverallLeaderboard.vue";
import EntryLeaderboard from "@/components/EntryLeaderboard.vue";
import PlayerList from "@/components/PlayerList.vue";
import NavBar from "@/components/NavBar.vue";

export default {
    components: {
        NavBar,
        PlayerList,
        EntryLeaderboard,
        OverallLeaderboard,
        Gameweeks,
        PriceChangeStats,
        Explanation,
        SelectionStats,
        TransferStats,
        TeamOfTheWeek,
        BetaText,
        Standings,
        Fixtures
    },
    setup(props) {
        const store = useDefaultStore()
        const seasonStore = useSeasonStore()

        useSeoMeta({
            title: () => 'Fantasy Liga MX | Mexican Fantasy Football',
            description: () => 'Play Fantasy Lig MX the best Fantasy Football App for Mexican Liga MX'
        })

        onMounted(() => {
            const activeSeason = _.find(store.seasons, season => {
                return season.nation_code === 'mx'
                    && ['active', 'preview'].includes(season.status)
            })
            if (activeSeason) {
                seasonStore
                    .init(activeSeason.id)
                    .then(() => {
                        gameweekIsOpen.value = seasonStore.gameweekIsOpen()
                    })
            }
        })

        const season = computed(() => seasonStore.season)

        const teams = computed(() => seasonStore.teams)

        const gameweekIsOpen = ref(null)

        return {season, teams, gameweekIsOpen}
    }
}
</script>
