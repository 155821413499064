import {defineStore} from 'pinia'
import {useFlashStore} from "@/stores/flashStore";

export const useDefaultStore = defineStore('defaultStore', {
    state: () => ({
        appLoaded: false,
        seasons: undefined,
        authUser: undefined,
        discordLink: undefined,
        title: undefined,
        theme: 'default'
    }),
    actions: {
        async init() {
            this.appLoaded = false
            /*
            await axios.get('/sanctum/csrf-cookie').then(response => {
                console.log('done the cookie thing')
            });
            */

            try {
                const response = await axios.get('/api/init')
                this.seasons = _.keyBy(response.data.seasons, 'id')
                this.discordLink = response.data.discord_link
                this.title = response.data.title
                this.theme = response.data.theme
                this.setAuthUser(response.data.auth_user)
                this.appLoaded = true
            } catch (error) {
                console.log(error)
            }
        },
        async login(email, password, remember = true) {
            const response = await axios.post('/api/auth/login', {
                email: email,
                password: password,
                remember: remember
            })

            this.setAuthUser(response.data.user)

            await this.init()
        },
        async register(name, email, password, geo = {}) {
            const response = await axios.post('/api/auth/register', {
                name: name,
                email: email,
                password: password,
                geo: geo
            })

            this.setAuthUser(response.data.user)
            this.authEntry = response.data.entry
        },
        async updateAuth(payload) {
            const response = await axios.patch('/api/auth', payload)

            this.setAuthUser(response.data.user)
        },
        async logout() {
            try {
                axios
                    .post('/api/auth/logout').then(response => {
                        // Refresh the page otherwise we get "CSRF token mismatch" errors
                        location.reload()
                    })
                    .then(response => {
                        useFlashStore().setFlash('success', 'Your are now logged out!')
                    })
                    .catch(error =>  {
                        useFlashStore().axiosError(error, 'Failed to logout')
                    })
            } catch (error) {
                console.error(error)
            }
        },
        setAuthUser(user) {
            this.authUser = user
            if (user) {
                window.setBugsnagUser(this.authUser.id, this.authUser.email, this.authUser.name)
            } else {
                window.setBugsnagUser(null, null, null)
            }
        }
    },
})
