<template>
    <mod-gameweeks-table
        :editable="true"
    ></mod-gameweeks-table>
    <div class="pt-3">
        <router-link :to="{name:'mod-create-gameweek'}" class="btn success">
            Create Gameweek
        </router-link>
    </div>
</template>

<script>
import { useSeoMeta } from "@unhead/vue";
import {useSeasonStore} from "@/stores/seasonStore";
import ModGameweeksTable from "@/components/ModGameweeksTable.vue";

export default {
    components: {ModGameweeksTable},
    props: {
        seasonId: {
            type: [Number, String],
            required: true
        }
    },
    setup() {
        const seasonStore = useSeasonStore();

        useSeoMeta({
            title: () => 'Mod Gameweeks | ' + seasonStore.season.name,
            description: () => 'Mod Gameweeks ' + seasonStore.season.name
        });
    }
};
</script>
