<template>
    <tr>
        <td>
            <i class="fa-solid fa-trash cursor-pointer" @click.once="deletePlayerFixture" data-customTooltip="Delete Row" data-tooltip-position="right"></i>
        </td>
        <th>
            <router-link
                :to="{name: 'mod-player', params: {playerId: player.id}}"
            >
                {{ player.name }}
            </router-link>
            <div class="small" style="font-weight: normal">
                <span>{{ player.position }}</span>
                <span class="ml-1">
                    <img v-if="team.image_path" :src="team.image_path" style="width:16px;display:inline" />
                    {{ team.short_name }}
                </span>
            </div>
        </th>
        <td>
            <select
                v-model="localDraft.status"
                :class="{ warning: localDraft.status !== playerFixture.status }"
                style="width:120px;"
                @change="emitChanges"
                @keydown.enter="saveData"
            >
                <option
                    v-for="(statusLabel, statusValue) in statusOptions"
                    :value="statusValue"
                >
                    {{ statusLabel }}
                </option>
            </select>
        </td>
        <template v-for="column in columns">
            <td v-if="column.field === 'points'">
                {{ playerFixture.points }}
            </td>
            <td v-else>
                <form @submit.prevent="saveData">
                    <input
                        type="number"
                        v-model="localDraft[column.field]"
                        @input="emitChanges"
                        :placeholder="column.label"
                        :class="{ warning: localDraft[column.field] !== playerFixture[column.field] }"
                    />
                </form>
            </td>
        </template>
    </tr>
</template>

<script>
import {computed, ref, watch} from "vue";
import { useSeasonStore } from "../stores/seasonStore";

export default {
    emits: ['update:modelValue', 'saveRow'],
    props: {
        fixture: {
            type: Object,
            required: true
        },
        playerFixture: {
            type: Object,
            required: true
        },
        modelValue: {
            type: Object,
            default: () => ({})
        },
        columns: {
            type: Object,
            required: true
        }
    },
    setup(props, { emit }) {
        const seasonStore = useSeasonStore()

        const statusOptions = {
            full_match: 'Full Match',
            bench: 'Bench',
            subbed_on: 'Subbed On',
            subbed_off: 'Subbed Off',
        }

        const localDraft = ref({ ...props.modelValue });

        watch(
            () => props.modelValue,
            (newVal) => {
                localDraft.value = { ...newVal }; // Update localDraft if modelValue changes
            }
        );

        const emitChanges = () => {
            emit('update:modelValue', localDraft.value);
        };

        const saveData = () => {
            emit('saveRow', props.playerFixture.id); // Emit event to save the row in parent
        }

        const deletePlayerFixture = () => {
            emit('deleteRow', props.playerFixture.id); // Emit event to save the row in parent
        }

        const player = computed(() => {
            return seasonStore.players[props.playerFixture.player_season_id]
        })

        const team = computed(() => {
            return seasonStore.teams[props.playerFixture.team_id]
        })

        return { statusOptions, player, team, localDraft, emitChanges, saveData, deletePlayerFixture };
    }
};
</script>
