<template>
    <div>
        <div id="create-player-form" class="box form">
            <h1>Create Gameweek</h1>
            <form @submit.prevent="submitForm">
                <div class="mb-3">
                    <label for="number">Number</label>
                    <input type="number" id="number"
                           required
                           v-model="number">
                </div>
                <div class="mb-3">
                    <label for="start_at">Start At</label>
                    <input type="date" id="start_at"
                           required
                           v-model="start_at">
                    <small>If planning ahead do a few days before the first fixture.</small>
                </div>
                <div class="mb-3">
                    <label for="end_at">End At</label>
                    <input type="date" id="end_at"
                           required
                           v-model="end_at">
                    <small>If planning ahead do a few days after the last fixture.</small>
                </div>
                <div class="mb-3">
                    <label for="free_transfers">Free Transfers</label>
                    <input type="number" id="free_transfers"
                           v-model="free_transfers">
                    <small>Leave blank to allow unlimited fixtures.</small>
                </div>
                <div class="mb-3">
                    <label for="max_free_transfers">Max Free Transfers</label>
                    <input type="number" id="max_free_transfers"
                           required
                           v-model="max_free_transfers">
                </div>
                <div class="mb-3">
                    <label for="rollover_free_transfers">Rollover Free Transfers</label>
                    <input type="number" id="rollover_free_transfers"
                           required
                           v-model="rollover_free_transfers">
                </div>
                <div class="mb-3">
                    <label for="cost_per_transfer">Cost Per Transfer</label>
                    <input type="number" id="cost_per_transfer"
                           v-model="cost_per_transfer">
                </div>
                <div class="mb-3">
                    <label for="picks_per_team">Picks Per Team</label>
                    <input type="number" id="picks_per_team"
                           v-model="picks_per_team">
                </div>
                <div class="mb-3">
                    <button type="submit" :disabled="!isValid" class="success">
                        Create Gameweek
                    </button>
                    <router-link :to="{name:'mod-gameweeks'}" class="btn danger">
                        Cancel
                    </router-link>
                </div>
            </form>
        </div>
    </div>
    <mod-gameweeks-table
        :editable="false"
    ></mod-gameweeks-table>
</template>
<script>
import {computed, onMounted, ref} from "vue";
import FormButton from "../elements/FormButton.vue";
import {useSeasonStore} from "../stores/seasonStore";
import router from "../routes";
import {useSeoMeta} from "@unhead/vue";
import {useFlashStore} from "@/stores/flashStore";
import ModGameweeksTable from "@/components/ModGameweeksTable.vue";

export default {
    components: {ModGameweeksTable, FormButton},
    setup(props, {emit}) {
        useSeoMeta({
            title: 'Create Gameweek',
            description: 'Create a new gameweek for the season',
        })

        const seasonStore = useSeasonStore()

        const season = computed(() => seasonStore.season)

        const number = ref(_.max(_.map(seasonStore.gameweeks, 'number')) + 1)
        const free_transfers = ref(season.value.rollover_free_transfers)
        const rollover_free_transfers = ref(season.value.rollover_free_transfers)
        const max_free_transfers = ref(season.value.max_free_transfers)
        const cost_per_transfer = ref(season.value.cost_per_transfer)
        const picks_per_team = ref(3)
        const start_at = ref(null)
        const end_at = ref(null)

        const isValid = computed(() => {
            return number.value
                && start_at.value
                && end_at.value
        })


        const submitForm = () => {
            if (isValid.value) {
                seasonStore.createGameweek({
                    season_id: season.value.id,
                    number: number.value,
                    free_transfers: free_transfers.value,
                    rollover_free_transfers: rollover_free_transfers.value,
                    max_free_transfers: max_free_transfers.value,
                    cost_per_transfer: cost_per_transfer.value,
                    picks_per_team: picks_per_team.value,
                    start_at: start_at.value,
                    end_at: end_at.value
                })
                    .then(() => {
                        useFlashStore().setFlash('success', 'Gameweek Created')

                        router.push({name: 'mod-gameweeks'})
                    })
                    .catch(error => useFlashStore().axiosError(error, 'Gameweek Creation Failed'));
            } else {
                useFlashStore().setFlash('error', 'Form is not valid')
            }
        }

        return {
            season,
            isValid,
            number,
            free_transfers,
            rollover_free_transfers,
            max_free_transfers,
            cost_per_transfer,
            picks_per_team,
            start_at,
            end_at,
            submitForm
        }
    }
}
</script>
