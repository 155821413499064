<template>
    <div>
        <div class="mx-3 mb-3 flex items-center">
            <button @click="saveAll" class="btn success">Save All</button>
            <p class="ml-3">Press enter on any input to save that row. Or click "Save All" to save all rows. Yellow background indicates data is not saved.</p>
        </div>
        <table>
            <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Short Name</th>
            </tr>
            </thead>
            <tbody>
            <team-row
                v-for="team in teams"
                :key="team.id"
                :team="team"
                v-model="draft[team.id]"
                @saveRow="saveRow"
            ></team-row>
            </tbody>
        </table>
    </div>
</template>

<script>
import { useSeoMeta } from "@unhead/vue";
import { useSeasonStore } from "@/stores/seasonStore";
import { useFlashStore} from "@/stores/flashStore";
import { computed, ref } from "vue";
import TeamRow from "@/components/TeamRow.vue";

export default {
    components: { TeamRow },
    props: {
        seasonId: {
            type: [Number, String],
            required: true
        }
    },
    setup() {
        const seasonStore = useSeasonStore();
        const flashStore = useFlashStore();

        useSeoMeta({
            title: () => 'Mod Teams | ' + seasonStore.season.name,
            description: () => 'Mod Teams ' + seasonStore.season.name
        });

        const teams = computed(() => {
            return seasonStore.teams;
        });

        const draft = ref(
            {...seasonStore.teams}
        );

        const saveRow = (teamId) => {
            seasonStore.updateTeam(teamId, draft.value[teamId])
                .then(() => {
                    flashStore.setFlash('success', 'Team Updated')
                })
                .catch(error => flashStore.axiosError(error, 'Error Team Not Updated'));
        };

        const saveAll = () => {
            seasonStore.updateTeams(draft.value)
                .then(() => {
                    flashStore.setFlash('success', 'All Teams Updated')

                    draft.value = {...seasonStore.teams};
                })
                .catch(error => flashStore.axiosError(error, 'Error Teams Not Updated'));
        };

        return { teams, draft, saveAll, saveRow };
    }
};
</script>
